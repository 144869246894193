import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
// import "firebase/app-check";
import "firebase/analytics";



// Web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later
var firebaseConfig = {
  apiKey: "AIzaSyDE6TkT8lTlYEEsrkz9Y4FDrDlBOanobPw",
  authDomain: "rate-it-2a52d.firebaseapp.com",
  databaseURL: "https://rate-it-2a52d-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "rate-it-2a52d",
  storageBucket: "rate-it-2a52d.appspot.com",
  messagingSenderId: "363602627884",
  appId: "1:363602627884:web:7f5c55c9d04795823db089",
  measurementId: "G-WD8P0MTJ1E"
};
// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
  
// export const appCheck = firebase.appCheck();
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
// Second argument is optional. If true, the SDK automatically refreshes App Check tokens as needed.
// appCheck.activate('6LdfbxMcAAAAACXxtmv28nmNhclV4QjQp_9D7VVI', true);
    
export const auth = app.auth();
export const db = app.firestore();
export const firebaseAnalytics = app.analytics();
export default app;
