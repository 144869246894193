import React, { useContext, useEffect, useState } from "react";
import { useAuth } from "./AuthContext";
import { db } from "../Utils/firebaseInit";

const DatabaseContext = React.createContext();

export function useDatabase() {
  return useContext(DatabaseContext);
}

export function DatabaseProvider({ children }) {
  // STATES
  const [message, setMessage] = useState({ msg: "", type: "" });
  const [userProfile, setUserProfile] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [userInitialsStr, setUserInitialsStr] = useState("");
  const [newUserCreated, setNewUserCreated] = useState(false);
  // CONTEXTS
  const { isLogedIn, currentUser } = useAuth();
  // DATABASE API
  const usersRef = db.collection("users");
  const categoriesRef = db.collection("categories");
  // FUNCTIONS
  const curentDateInMs = new Date().getTime();
  const currentDateString = String(Date());

  const write = () => {
    usersRef.doc("SB").set(
      {
        nic: "wind",
        name: "San Francisco",
        state: "CA",
        country: "USA",
        capital: false,
        population: 860000,
        regions: ["west_coast", "norcal"],
      },
      { merge: true }
    );
  };
  const read = () => {
    usersRef
      .doc("zpsloFHMwWO6p0OIyyC8")
      .get()
      .then((doc) => {
        let dataString = JSON.stringify(doc.data(), null, 4);
        console.log(dataString);
        console.log(doc.exists);
      });
  };
  const postToDb = (colectionStringName, dataObject) => {
    db.collection(colectionStringName)
      .add(dataObject)
      .then((docRef) => {
        console.log("Form was posted succesfuly");
      })
      .catch((error) => {
        setErrorMessage(error);
        console.error("Error adding document: ", error);
      });
  };
  const getUserProfile = (userAuthObject) => {
    // search the user profile record and if you don't find him
    // create new user profile record
    usersRef
      .doc(userAuthObject.uid)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          console.log("User profile do not exist");
          console.log("Creating new user profile...");
          createUserProfile(userAuthObject);
        } else {
          setUserProfile(doc.data());
          doc.data().nic !== ""
            ? getUserInitials(doc.data().nic)
            : getUserInitials(doc.data().name);
        }
      })
      .catch((error) => {
        console.error("Error geting document: ", error);
      });
  };
  const createUserProfile = (userAuth) => {
    try {
      usersRef.doc(userAuth.uid).set(
        {
          name: userAuth.displayName,
          email: userAuth.email,
          photoURL: userAuth.photoURL,
          nic: "",
          ratings: [],
          subjects: [],
          comments: [],
          nOfLikesTo: 0,
          nOfDislikesTo: 0,
          nOfLikesFrom: 0,
          nOfDislikesFrom: 0,
          lastLogin: {
            milliseconds: curentDateInMs,
            dateString: currentDateString,
          },
          firstLogin: {
            milliseconds: curentDateInMs,
            dateString: currentDateString,
          },
        },
        { merge: true }
      );
      setNewUserCreated(true);
      // run initial functioan again to populate user profile object
      getUserProfile(currentUser);
      console.log(
        "The creation of a new user profile was successfully finished"
      );
    } catch (error) {
      setErrorMessage(error);
      console.log("Error by creation of user. Message: " + error);
    }
  };
  const updateUserProfile = async (profile_id, nickname) => {
    let query = usersRef.where("nic", "==", nickname);
    query
      .limit(1)
      .get()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          querySnapshot.forEach((documentSnapshot) => {
            setMessage({
              msg: "Tento pseudonym nieje k dispozícii",
              type: "warning",
            });
          });
        } else {
          usersRef
            .doc(profile_id)
            .update({ nic: nickname })
            .then(() => window.location.reload());
        }
      });
  };
  const getUserInitials = (name_or_nickname_string) => {
    //Create initials from neme or nickname
    const strSplitedOnSpaces = name_or_nickname_string.split(" ");
    let initialsFromNickName = "";
    strSplitedOnSpaces.forEach((name) => {
      initialsFromNickName += name.slice(0, 1);
    });
    if (initialsFromNickName.length > 2) {
      initialsFromNickName = initialsFromNickName.slice(0, 2);
    }
    setUserInitialsStr(initialsFromNickName.toUpperCase());
  };
  const userNicnameCheck = (nickname) => {
    let query = usersRef.where("nic", "==", nickname);
    query
      .limit(1)
      .get()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          querySnapshot.forEach((documentSnapshot) => {
            setMessage({
              msg: "Tento pseudonym nieje k dispozícii",
              type: "warning",
            });
            return true;
          });
        } else {
          setMessage({
            msg: "Tento pseudonym je volný a čaká len na teba!",
            type: "success",
          });
          return false;
        }
      });
  };
  const getParentCategories = async () => {
    let arrOfCategories = [];
    let query = categoriesRef.where("parent", "==", "");
    query.get().then((category) => {
      if (!category.empty) {
        category.forEach((documentSnapshot) => {
          arrOfCategories.push({
            id: documentSnapshot.id,
            ...documentSnapshot.data(),
          });
        });
      } else {
        console.error("Filtru nevihovuje žiadna kategória");
      }
    });
    return arrOfCategories;
  };
  useEffect(() => {
    if (isLogedIn) {
      getUserProfile(currentUser);
    }
    //read();
    //write();
    //userNicnameCheck();
  }, [isLogedIn, currentUser]);

  const value = {
    userProfile,
    postToDb,
    newUserCreated,
    errorMessage,
    userNicnameCheck,
    userInitialsStr,
    setUserInitialsStr,
    updateUserProfile,
    message,
    setMessage,
    getParentCategories,
  };

  return (
    <DatabaseContext.Provider value={value}>
      {children}
    </DatabaseContext.Provider>
  );
}
