import React, { useRef, useEffect} from 'react'
import styled, { keyframes } from 'styled-components'
import img1 from '../../../../Img/Hero1.png'
import img2 from '../../../../Img/Hero2.jpg'
import { BiSearch } from "react-icons/bi";
import { useComponent } from "../../../../Contexts/ComponentContext";
import { Link, animateScroll as scroll } from "react-scroll";
import {ChevronDown} from '@styled-icons/evil/ChevronDown';


export const Hero = () => {
  
  let { setHeroOffset } = useComponent();
  let elmRef = useRef();
  
  useEffect(() => {
    setHeroOffset(elmRef.current.offsetHeight);
  }, [elmRef.current]);

  return (
    <HeroContainer ref={elmRef}>
      <FirstLayerImges>
        <FirstLayerImgOwerlay/>
        <TitleContainer>
          <TitleOwerlay>
            <Title>rateit.sk</Title>
            <Title>Tu ohodnotíš čokoľvek</Title>
            <MainSearch>
              <SearchInput 
                type="search" 
                name="search" 
                id="search" 
                placeholder="Zadaj hladaný pojem"
                aria-label="Search through all ratings"
              />
              <SubmitButton type="submit">
                <SearchIcone/>
              </SubmitButton> 
            </MainSearch>
          </TitleOwerlay>
          <AboutLinkS 
            to="about_us"
            smooth={true}
            offset={0}
            duration={500}
          >
            <h5>O nás</h5>
            <ArrowDownIconeLink/>
          </AboutLinkS>
          <BottomTitle>Love it ... ?  Hate it ... ? Rate it!</BottomTitle>
        </TitleContainer>
      </FirstLayerImges>
    </HeroContainer>
  )
}

// ---- STYLES ----
const HeroContainer = styled.div`
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  margin-top: -50px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100vw;
  height: 100vh;
  overflow: hidden;
  @media screen and (max-width: 700px) {
    margin-top: -50px;
  }
`
const FirstLayerImges = styled.div`
  position: relative;
  width: 100%;
  max-width: 100vw;
  height: 100%;
  padding: 0;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url(${img1}), url(${img2});
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 100;
  @media screen and (max-width: 1100px) {
    
  }
  @media screen and (max-width: 800px) {
    
  }
  @media screen and (max-width: 400px) {
    
  }
`
const FirstLayerImgOwerlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 300;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);

`
const TitleContainer = styled.div`
  position: relative;
  z-index: 400;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  padding-top: calc(13% + 50px);
  width: 100%;
  height: 100%;
  @media screen and (max-width: 1100px) {
    padding-top: 30%;
  }
  @media screen and (max-width: 700px) {
    padding-top: 55%;
  }
  `
const TitleOwerlay = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  padding: 15px 15px 25px 15px;
  max-width: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  @media screen and (min-width: 2500px) {
    padding: 35px 35px 45px 35px;
  }
`

const Title = styled.h1`
  color: white;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 50px;
  margin: 10px 0;
  @media screen and (max-width: 400px) {
    font-size: 40px;
  }
`
const MainSearch = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  text-align: center;
  color: white;
  border-radius: 3px;
  border: 2px solid gray;
  width: 450px;
  height: 45px;
  max-width: 90%;
  margin-top: 20px;
  :active, :focus, :focus-visible, :focus-within {
    border-color: orange;
  }
  /* Must be separtate for Safari*/
  :focus-within {
    border-color: orange;
  } 
`

const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  padding: 10px;
  font-size: 20px;
  text-align: left;
  border: none;
  outline: none;
  background: none;
  color: whitesmoke;
  ::placeholder {
    color: whitesmoke;
  }
  ::-webkit-search-cancel-button {
    appearance: none;
  }
`
const SubmitButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  height: 100%;
  padding: 0 7px;
  cursor: pointer;
  border: none;
  background: none;
`
const SearchIcone = styled(BiSearch)`
  color: whitesmoke;
  font-size: 30px;
`
const AboutLinkS = styled(Link)`
  position: absolute;
  bottom: 0;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  color: whitesmoke;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
`
const Slow_Pulsar = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`
const ArrowDownIconeLink = styled(ChevronDown)`
  height: 35px;
  font-weight: 400;
  opacity: 0;
  animation-name: ${Slow_Pulsar};
  animation-duration: 2s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
`
const BottomTitle = styled.h4`
  position: absolute;
  bottom: 0;
  align-self: flex-end;
  padding-right: 5px;
  color: whitesmoke;
  @media screen and (max-width: 700px) {
    display: none;
  }
`