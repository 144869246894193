import React from "react";
import styled from "styled-components";
import { useAuth } from "../../../../../Contexts/AuthContext";
import { useComponent } from "../../../../../Contexts/ComponentContext";
import { UserMenu } from "./UserMenu";

export const LogInLogOut = () => {
  const { isLogedIn, logout } = useAuth();
  const { setDisplayLogin, setCompLoading } = useComponent();

  const logoutClick = () => {
    setCompLoading(true);
    logout();
    setTimeout(() => {
      setCompLoading(false);
    }, 700);
  };

  if (isLogedIn) {
    return (
      <ButtonContainer>
        <UserMenu />
        <LogOutButton onClick={() => logoutClick()}>Logout</LogOutButton>
      </ButtonContainer>
    );
  } else {
    return (
      <ButtonContainer>
        <LogInButton href="#login_form" onClick={() => setDisplayLogin(true)}>
          Log-in
        </LogInButton>
      </ButtonContainer>
    );
  }
};
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
`;

const LogInButton = styled.a`
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  box-sizing: border-box;
  border: none;
  color: orange;
  background-color: transparent;
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;
  padding: 5px 15px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid transparent;
  :hover {
    border-bottom: 2px solid orange;
  }
  @media screen and (max-width: 700px) {
    font-size: 25px;
    text-align: center;
  }
`;
const LogOutButton = styled.button`
  display: none;
  @media screen and (max-width: 700px) {
    padding: 5px 0;
    display: block;
    width: 100%;
    height: 100%;
    font-family: "Roboto", Tahoma;
    box-sizing: border-box;
    border: none;
    color: orange;
    white-space: nowrap;
    background-color: transparent;
    cursor: pointer;
    font-size: 25px;
    text-align: center;
  }
`;
