import React from "react";
import styled from "styled-components";

export const RateItLogo = ({props}) => {
  return (
    <LinkContainer href="/">
      <FullWidhtContainer>
        <LeftCube>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </LeftCube>

        <CenterCube>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </CenterCube>

        <RightCube>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </RightCube>
        <LogoTitle>Rate it</LogoTitle>
      </FullWidhtContainer>
    </LinkContainer>
  );
};
const LinkContainer = styled.a`
  position: relative;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
const FullWidhtContainer = styled.div`
  position: relative;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  div {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  div > div {
    width: 16px;
    height: 16px;
    margin: 0;
    padding: 0;
    transform: revert;
  }
`;
const LeftCube = styled.div`
  transform: translateX(9.5px) rotate(45deg);
  div:nth-child(1) {
    background: linear-gradient(
      -45deg,
      rgba(245, 136, 30, 1) 35%,
      rgba(16, 194, 231, 1)
    );
  }
  div:nth-child(2) {
    background:  rgba(244, 135, 30, 1);
  }
  div:nth-child(3) {
    background: linear-gradient(-135deg, rgba(245, 136, 30, 1) 35%, rgb(255, 60, 0));
  }
  div:nth-child(4) {
    background: linear-gradient(
      135deg,
      rgba(245, 136, 30, 1) 35%,
      rgba(144, 227, 2, 1)
    );
  }
`;
const CenterCube = styled.div`
  transform: rotate(45deg);
  div:nth-child(2),
  div:nth-child(3) {
    visibility: hidden;
  }
  div:nth-child(1) {
    background: linear-gradient(
      -45deg, 
      rgba(245, 136, 30, 1) 35%,
      rgba(144, 227, 2, 1)
    );
  }
  div:nth-child(4) {
    background: linear-gradient(
      135deg,
      rgba(245, 136, 30, 1) 35%,
      rgba(16, 194, 231, 1)
    );
  }
`;
const RightCube = styled.div`
  transform: translateX(-9.5px) rotate(45deg);
  div {
    border: hidden;
  }
  div:nth-child(1) {
    background: linear-gradient(
      -45deg,
      rgba(245, 136, 30, 1) 35%,
      rgba(16, 194, 231, 1)
    );
  }
  div:nth-child(2) {
    background: linear-gradient(45deg, rgba(245, 136, 30, 1) 35%, rgb(255, 60, 0));
  }
  div:nth-child(3) {
    background:  rgba(244, 135, 30, 1);
  }
  div:nth-child(4) {
    background: linear-gradient(
      135deg,
      rgba(245, 136, 30, 1) 35%,
      rgba(144, 227, 2, 1)
    );
  }
`;
const LogoTitle = styled.h1`
  position: absolute;
  left: 0;
  z-index: 10;
  width: 100%;
  text-align: center;
  font-family: 'Damion', cursive;
  font-size: 1.75rem;
  font-weight: 400;
  transform: translate(-2%, -5%);
  color: black;

`