import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link as LinkR, useLocation } from "react-router-dom";
import { LogInLogOut } from "./LogInLogOut";
import { Link, animateScroll as scroll } from "react-scroll";
import { useComponent } from '../../../../../Contexts/ComponentContext';

export const MainMenu = () => {
  const location = useLocation();
  const [ isUrlHome, setIsUrlHome ] = useState(true);
  const [ isActiveOne, setIsActiveOne ] = useState("");
  const [ isActiveTwo, setIsActiveTwo ] = useState("");
  const { setDisplayUserMenu, userMenuClass, setUserMenuClass } = useComponent();

  const toggleMenuVisibility = () => {
    if (userMenuClass === "visible") {
      setUserMenuClass("");
      setDisplayUserMenu(false);
    } 
  }
  const resetLinkStates = () => {
    setIsActiveOne("");
    setIsActiveTwo("");
  }
/*   const scrollToContactForm = () => {
    history.pushState({}, "", location.origin + "/#contact_form")
    const element = document.getElementById("contact_us");
    element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  } */

  useEffect(() => {
      if (location.pathname === "/") {
        setIsUrlHome(true);
      } else {
        setIsUrlHome(false);
      }
      //console.log(location.pathname);
      if (location.pathname.indexOf("/pridaj-hodnotenie") > -1) {
        setIsActiveOne("active");
        setIsActiveTwo("");
      } else if (location.pathname.indexOf("/vyhladaj-hodnotenie" ) > -1) {
        setIsActiveOne("");
        setIsActiveTwo("active");
      } else {
        resetLinkStates();
      }


  }, [location])
  
  return (
    <MainMenuContainer>
      <MenuLinkR 
        to="/pridaj-hodnotenie" 
        onClick={toggleMenuVisibility}
        className={isActiveOne}
        >Pridaj hodnotenie</MenuLinkR>
      <MenuLinkR
        to="/vyhladaj-hodnotenie" 
        onClick={toggleMenuVisibility}
        className={isActiveTwo}
        >Vyhľadaj hodnotenie</MenuLinkR>
      {isUrlHome ? 
        <MenuLinkA 
          activeClass="active"
          to="contact_us"
          spy={true}
          smooth={true}
          offset={0}
          duration={500}
          onClick={toggleMenuVisibility}
        >Navrhni vylepšenie</MenuLinkA>
        :
        <MenuLinkR 
          to="/#contact_us"
          //onClick={scrollToContactForm}
          >Navrhni vylepšenie</MenuLinkR>
      } 
       <LogInLogOut />
    </MainMenuContainer>
  );
};
// ---- STYLES ----

const MainMenuContainer = styled.nav`
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-self: flex-end;
  margin-left: auto;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  border-radius: 0;
  color: #60d9fa;
  cursor: pointer;
  @media screen and (max-width: 700px) {
    display: none;
  }
`
const MenuLinkR = styled(LinkR)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  white-space: nowrap;
  color: whitesmoke;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 15px;
  border-bottom: 2px solid transparent;
  :hover,
  :active,
  &.active {
    border-bottom-color: orange;
  }
`;
const MenuLinkA = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  white-space: nowrap;
  color: whitesmoke;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 15px;
  :hover {
    border-bottom: 2px solid orange;
    padding-top: 2px;
  }

  &.active {
    border-bottom: 2px solid orange;
    padding-top: 2px;
  }
`;
