import React, { useState, useEffect } from "react";
import styled from "styled-components";

export const FieldMessage = ({ disabledClass, message }) => {
  const [messageText, setMessageText] = useState(
    "V pseudonime je povolená len jedna medzera a maximálne 20 znakov"
  );
  useEffect(() => {
    if (message.msg !== "") {
      setMessageText(message.msg);
    }
  }, [message]);

  return (
    <Container className={disabledClass + " " + message.type}>
      <Triangle />
      <p>{messageText}</p>
    </Container>
  );
};

// STYLES

const Container = styled.div`
  position: relative;
  width: 320px;
  border-radius: 4px 4px 4px 4px;
  padding: 5px 10px;
  padding-bottom: 10px;
  background-color: rgba(3, 168, 244, 0.773);
  color: whitesmoke;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: background-color 250ms linear;
  &.disabled {
    transition: background-color 250ms linear;
    background-color: rgba(128, 128, 128, 0.534);
    span {
      transition: border-bottom-color 250ms linear;
      border-bottom-color: rgba(128, 128, 128, 0.534);
    }
  }
  &.warning {
    transition: background-color 250ms linear;
    background-color: rgba(255, 86, 34, 0.534);
    span {
      transition: border-bottom-color 250ms linear;
      border-bottom-color: rgba(255, 86, 34, 0.534);
    }
  }
  &.error {
    transition: background-color 250ms linear;
    background-color: rgba(244, 67, 54, 0.534);
    span {
      transition: border-bottom-color 250ms linear;
      border-bottom-color: rgba(244, 67, 54, 0.534);
    }
  }
  &.success {
    transition: background-color 250ms linear;
    background-color: rgba(76, 175, 79, 0.534);
    span {
      transition: border-bottom-color 250ms linear;
      border-bottom-color: rgba(76, 175, 79, 0.534);
    }
  }

  @media (max-width: 700px) {
    width: 100%;
  }
`;
const Triangle = styled.span`
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: solid rgba(3, 168, 244, 0.773) 10px;
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
`;
