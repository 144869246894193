import React from 'react';
import styled from 'styled-components';
import { Header } from '../../../src/Pages/Home_page/components/Header/Header';

export const InDevelopmentPage = () => {
  return (
      <MainContainer>
        <Header />
        <ContentContainer>
          <h1>Táto stránka je aktuálne vo vývoji</h1>
        </ContentContainer>
      </MainContainer>
  )
}
// ------ STYLES -------
const MainContainer = styled.div`
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  background-color: whitesmoke;
`
const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    text-decoration: underline;
    margin-top: 17%;
    font-size: 3rem;
    font-family: Roboto, sans-serif;
    text-decoration-color: orange;
  }
`
