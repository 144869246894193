import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { useFormik } from "formik";
import { useDatabase } from "../../../../Contexts/DatabaseContext";

export const ContactForm = () => {
  const [displayMessage, setDisplayMessage] = useState("displayNone");
  const { postToDb } = useDatabase();
  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Povinné";
    } else if (values.name.length > 30) {
      errors.name = "Musí byť dlhšie ako 4 a kratšie ako 30 znakov";
    } else if (values.name.length < 4) {
      errors.name = "Musí byť dlhšie ako 4 a kratšie ako 30 znakov";
    }

    if (!values.email) {
      errors.email = "Povinné";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Nesprávny formát emailovej adresy";
    }

    if (!values.message) {
      errors.message = "Povinné";
    } else if (values.message.length < 15) {
      errors.message = "Správa musí mať viac ako 15 znakov";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
      gdpr: "",
      created_on: "",
      created_on_dt: "",
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      setDisplayMessage("animate");
      values.created_on = new Date().getTime();
      values.created_on_dt = Date();
      postToDb("contact_form", values);
      setTimeout(() => {
        resetForm({});
        document.getElementById("gdprId").checked = false;
      }, 1000);
    },
  });
  useEffect(() => {
    if (displayMessage === "animate") {
      setTimeout(() => {
        setDisplayMessage("displayNone");
      }, 4000);
    }
  }, [displayMessage]);

  return (
    <Container>
      <TitleContainer>
        <LeftTitle>Kontaktujte nás</LeftTitle>
      </TitleContainer>
      <Form action="" method="post" onSubmit={formik.handleSubmit}>
        <MessageBox className={displayMessage}>
          <h2>Vaša správa bola úspešne odoslaná</h2>
          <h2>Ďakujeme</h2>
        </MessageBox>
        <RowContainer>
          <Label htmlFor="nameId">Meno</Label>
          <StaticSizeContainer>
            {formik.touched.name && formik.errors.name ? (
              <ErrotMsg>{" - " + formik.errors.name}</ErrotMsg>
            ) : null}
          </StaticSizeContainer>
          <Input
            type="text"
            name="name"
            id="nameId"
            required
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
          />
        </RowContainer>
        <RowContainer>
          <Label htmlFor="emailId">Email</Label>
          <StaticSizeContainer>
            {formik.touched.email && formik.errors.email ? (
              <ErrotMsg>{" - " + formik.errors.email}</ErrotMsg>
            ) : null}
          </StaticSizeContainer>
          <Input
            type="email"
            name="email"
            id="emailId"
            required
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
        </RowContainer>
        <RowContainer>
          <Label htmlFor="messageId">Správa</Label>
          <StaticSizeContainer>
            {formik.touched.message && formik.errors.message ? (
              <ErrotMsg>{" - " + formik.errors.message}</ErrotMsg>
            ) : null}
          </StaticSizeContainer>
          <Textarea
            name="message"
            id="messageId"
            cols="30"
            rows="10"
            required
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.message}
          />
        </RowContainer>
        <RowContainer>
          <CheckBox
            type="checkbox"
            name="gdpr"
            id="gdprId"
            required
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.gdpr}
          />
          <label htmlFor="gdprId">
            Súhlasím so spracovaním osobných údajov. Viac informácií{" "}
            <a href="http://" className="label_anchor">
              tu.
            </a>
          </label>
        </RowContainer>
        <SubmitButton type="submit">Odoslať správu</SubmitButton>
      </Form>
    </Container>
  );
};

// ---- STYLES ----
const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`
const TitleContainer = styled.div`
  position: relative;
  min-width: 39px;
  display: none; 
  @media screen and (max-width: 1020px) and (min-width: 770px) {
    display: block;
  }
`
const LeftTitle = styled.h2`
  position: absolute;
  top: 0;
  right: 0;
  color: orange;
  font-size: 2em;
  transform-origin: bottom right;
  transform: rotate(270deg) translate(20px, -10px);
  white-space: nowrap;
  border-bottom: 4px solid orange;
`;


const Form = styled.form`
  position: relative;
  overflow: hidden;
  padding: 5px 5px 5px 5px;
  &grecaptcha-badge {
    z-index: 100;
  }
`;
const FadeInFadeOut = keyframes`
  0% {
    left: 100vw;
  }
  20% {
    left: 0;
  }
  90% {
    left: 0;
  }
  100% {
    left: 100vw;
  }
`;

const MessageBox = styled.div`
  position: absolute;
  top: 0;
  left: calc(100vw / 2);
  width: 100%;
  height: 100%;
  z-index: 1000;
  color: whitesmoke;
  background-color: gray;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  &.animate {
    animation-name: ${FadeInFadeOut};
    animation-duration: 4s;
    animation-timing-function: ease-in-out;
  }
  &.displayNone {
    display: none;
  }
`;


const RowContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  flex-wrap: wrap;
  align-items: center;
  text-align: left;
  margin: 10px 0;

  .label_anchor {
    color: orange;
  }
`;
const Label = styled.label`
  display: inline;
  padding-bottom: 3px;
  overflow: hidden;

  .label_anchor {
    color: orange;
    font-size: 20px;
  }
`;
const Input = styled.input`
  width: 100%;
  max-width: 100%;
  padding: 5px;
  border-radius: 4px;
  border: none;
  outline-color: orange;
`;
const Textarea = styled.textarea`
  width: 100%;
  max-width: 100%;
  border-radius: 4px;
  border: none;
  resize: none;
  padding: 5px;
  outline-color: orange;
`;
const CheckBox = styled.input`
  margin-right: 10px;
  height: 20px;
  width: 20px;
  cursor: pointer;
`;
const SubmitButton = styled.button`
  color: orange;
  background-color: transparent;
  border: 2px solid orange;
  padding: 7px 10px;
  font-size: 20px;
  border-radius: 10px;
  :active {
    background-color: orange;
    color: whitesmoke;
  }
`;
const ErrotMsg = styled.div`
  text-align: left;
  margin: 0;
`;
const StaticSizeContainer = styled.div`
  display: inline;
  width: 350px;
  height: 100%;
  @media (max-width: 350px) {
    width: 100%;
  }
`;
