import React from 'react'
import styled, { keyframes } from 'styled-components'

export const Preloader = () => {
  return (
    <FullViewportContainer>
      <BorderPulsar>
        <LoadingText>
          Loading
        </LoadingText>
      </BorderPulsar>
    </FullViewportContainer>
  )
}

const FullViewportContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100000;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const Pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(61, 56, 56, 0.4);
  }
  70% {
      box-shadow: 0 0 0 30px rgba(61, 56, 56, 0);
  }
  100% {
      box-shadow: 0 0 0 0 rgba(61, 56, 56, 0);
  }
`
const BorderPulsar = styled.div`
  border: 2px solid black;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
  animation: pulse 2s infinite;
  animation-name: ${Pulse};
`
const LoadingText = styled.h1`
  text-align: center;
  font-weight: 700;
  font-family: 'Roboto', 'sans-serif';
`