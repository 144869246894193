import React, { useState, useEffect } from "react";
import { CgMenu } from "react-icons/cg";
import styled from "styled-components";
import { Link as LinkR, useLocation } from "react-router-dom";
import { LogInLogOut } from "./LogInLogOut";
import { VscClose } from "react-icons/vsc";
import { Link, animateScroll as scroll } from "react-scroll";
import { useComponent } from '../../../../../Contexts/ComponentContext';
import { RateItLogo } from '../../../../../Wigets/RateItLogo';
import { LightBulb } from '@styled-icons/heroicons-solid/LightBulb'
import { SavedSearch } from '@styled-icons/material/SavedSearch'
import { StarAdd } from '@styled-icons/fluentui-system-filled/StarAdd'
import { UserRectangle } from '@styled-icons/boxicons-solid/UserRectangle'
import { Gear } from '@styled-icons/octicons/Gear'



export const MobileMenu = () => {
  const location = useLocation();
  const [ isUrlHome, setIsUrlHome ] = useState(true);
  const [ isActiveOne, setIsActiveOne ] = useState("");
  const [ isActiveTwo, setIsActiveTwo ] = useState("");
  const [ isActiveThree, setIsActiveThree ] = useState("");
  const [ isActiveFour, setIsActiveFour ] = useState("");
  const [ menuIconClicked, setMenuIconClicked ] = useState(false);
  const { setDisplayMobileMenu } = useComponent();

  const resetLinkStates = () => {
    setIsActiveOne("");
    setIsActiveTwo("");
  }

  useEffect(() => {
    if (location.pathname === "/") {
      setIsUrlHome(true);
    } else {
      setIsUrlHome(false);
    }
    if (location.pathname.indexOf("/pridaj-hodnotenie") > -1) {
      setIsActiveOne("active");
      setIsActiveTwo("");
      setIsActiveThree("");
      setIsActiveFour("");
    } else if (location.pathname.indexOf("/vyhladaj-hodnotenie" ) > -1) {
      setIsActiveOne("");
      setIsActiveTwo("active");
      setIsActiveThree("");
      setIsActiveFour("");
    } else if (location.pathname.indexOf("/profil" ) > -1) {
      setIsActiveOne("");
      setIsActiveTwo("");
      setIsActiveThree("active");
      setIsActiveFour("");
    } else if (location.pathname.indexOf("/nastavenia" ) > -1) {
      setIsActiveOne("");
      setIsActiveTwo("");
      setIsActiveThree("");
      setIsActiveFour("active");
    } else {
      resetLinkStates();
    }
  }, [location])

  const toggleMenuButton = () => {
    setMenuIconClicked((oldState) => !oldState);
    setDisplayMobileMenu((oldState) => !oldState);
  };
  if (!menuIconClicked) {
    return (
      <HamburgerMenuIconContainer onClick={() => toggleMenuButton()}>
        <HamburgerMenuIcon />
      </HamburgerMenuIconContainer>
    );
  } else {
    return (
      <MainMenuContainer>
        <MenuHeader>
          <RateItLogo/>
          <MenuCloseIcone onClick={() => toggleMenuButton()} />
        </MenuHeader>
        <NavContainer>
          <MenuLinkR 
            to="/pridaj-hodnotenie"
            className={isActiveOne}
            onClick={() => toggleMenuButton()}
          >
            <StaticWidthContainer>
              <IconeContainer>
                <StarAdd/>
              </IconeContainer>
            </StaticWidthContainer>
            <LinkText>Pridaj hodnotenie</LinkText> 
          </MenuLinkR>

          <MenuLinkR 
            to="/vyhladaj-hodnotenie"
            className={isActiveTwo}
            onClick={() => toggleMenuButton()}
          > 
            <StaticWidthContainer>
              <IconeContainer style={{height: "32px", width: "32px"}}>
              <SavedSearch/>
              </IconeContainer>
            </StaticWidthContainer>
            <LinkText>Vyhľadaj hodnotenie</LinkText> 
          </MenuLinkR>

          {isUrlHome ? 
          <MenuLinkA 
            activeClass="active"
            to="contact_us"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            onClick={() => toggleMenuButton()}
          >
            <StaticWidthContainer>
              <IconeContainer style={{height: "32px", width: "32px"}}>
              <LightBulb/>
              </IconeContainer>
            </StaticWidthContainer>
            <LinkText>Navrhni vylepšenie</LinkText> 
          </MenuLinkA>
          :
          <MenuLinkR 
            to="/#contact_us"
            onClick={() => toggleMenuButton()}
          >
            <StaticWidthContainer>
              <IconeContainer style={{height: "32px", width: "32px"}}>
                <LightBulb/>
              </IconeContainer>
            </StaticWidthContainer>
            <LinkText>Navrhni vylepšenie</LinkText> 
          </MenuLinkR>
          }

          <MenuLinkR 
            to="/profil"
            className={isActiveThree}
            onClick={() => toggleMenuButton()}
          >
            <StaticWidthContainer>
              <IconeContainer>
                <UserRectangle/>
              </IconeContainer>
            </StaticWidthContainer>
            <LinkText>Profil</LinkText> 
          </MenuLinkR>

          <MenuLinkR 
            to="/nastavenia"
            className={isActiveFour}
            onClick={() => toggleMenuButton()}
          >
            <StaticWidthContainer>
              <IconeContainer style={{height: "24px", width: "24px"}}>
                <Gear/>
              </IconeContainer>
            </StaticWidthContainer>
            <LinkText>Nastavenia</LinkText> 
          </MenuLinkR>

        </NavContainer>
        <OrangeBorder>
          <LogInLogOut />
        </OrangeBorder>
      </MainMenuContainer>
    );
  }
};
// ---- STYLES ----

const MainMenuContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  padding-top: 65px;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: white;
  background-color: black;
  border-radius: 0;
  width: 100%;
  min-height: calc(100vh + 5px);
  @media screen and (min-height: 650px) {
    padding-top: 100px;
  }
`;
const MenuHeader = styled.div`
  position: absolute;
  top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const MenuCloseIcone = styled(VscClose)`
  color: orange;
  font-size: 40px;
  border: 2px solid orange;
  border-radius: 10px;
  margin-right: 10px;
  :active {
    color: whitesmoke;
  }
`;

const HamburgerMenuIconContainer = styled.div`
  display: none;
  @media screen and (max-width: 700px) {
    position: relative;
    display: block;
    justify-self: center;
    align-self: center;
    margin: 0 15px 0 auto;
  }
`;

const HamburgerMenuIcon  = styled(CgMenu)`
    height: 32px;
    width: 32px;
`
const NavContainer = styled.nav`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  a {
    display: flex;
    flex-direction: row;
    justify-content: left;
    width: 270px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`
const MenuLinkR = styled(LinkR)`
  text-decoration: none;
  color: whitesmoke;
  font-size: 25px;
  position: relative;
  width: 100%;
  padding: 15px 0;

  :active,
  &.active  {
    color: orange;
  }
`;
const MenuLinkA = styled(Link)`
  text-decoration: none;
  color: whitesmoke;
  font-size: 25px;
  position: relative;
  width: 100%;
  padding: 15px 0;
  cursor: pointer;
  &:active {
    color: whitesmoke;
    background-color: orange;
  }
`;

const LinkText = styled.span`
  text-align: left;
`
const StaticWidthContainer = styled.div`
  width: 35px;
  display: flex;
  flex-direction: row;
  justify-content: left;
`
const IconeContainer = styled.div`
  height: 26px;
  width: 26px;
`
const OrangeBorder = styled.div`
  border: 2px solid orange;
  border-radius: 10px;
  margin: 70px 16% 0 auto;
  width: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  :active {
    color: whitesmoke;
    background-color: orange;
  }
  div>button:active {
    color: whitesmoke;
  }

`
