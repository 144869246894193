import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useAuth } from '../Contexts/AuthContext';
import RateitLogo from '../Assets/img/rateit_logo_v1.png';
import { Close } from '@styled-icons/evil/Close';
import { useComponent } from '../Contexts/ComponentContext';

export const LoginForm = () => {
  const [ displayMessage, setDisplayMessage ] = useState("display");
  const { alternativeLogins } = useAuth();
  const { setDisplayLogin } = useComponent();
  const logInWith = async (provider, method) => {
    try {
      await alternativeLogins(provider, method);
    } catch (err) {
      console.log(err);
    }
  }

  const closeLoginForm = () => {
    setDisplayMessage("displayNone")
    setTimeout(() => {
      setDisplayLogin(false);
    }, 850);
  }

  return (
      <Container className={displayMessage} id="login_form">
        <CloseIconeContainer onClick={closeLoginForm}>
          <CloseIcone />
        </CloseIconeContainer>
        <Form className={displayMessage}>
          <Login>
            <AppLogo>
              <Logo>
                <RateItLogo src={RateitLogo} height= "266" width= "474" alt="Rate it logo"/>
              </Logo>
            </AppLogo>
            <TitleWrapper>
              <AltLoginTitle>Prihláste sa pomocou:</AltLoginTitle>
            </TitleWrapper>
            <RowContainer>       
              <IconContainerG 
                onClick={() => logInWith('google', 'redirect')}>
                  <GoogleIconImg 
                    src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" 
                    alt="google login icon"/>
                  <ProviderName >Google</ProviderName>
              </IconContainerG>

              <IconContainerF
                onClick={() => logInWith('facebook', 'redirect')}>
                  <FacebookIconImg 
                    src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/facebook.svg" 
                    alt="facebook login icon"/>
                  <ProviderName>Facebook</ProviderName>
              </IconContainerF>
            </RowContainer>    
          </Login>
        </Form>
      </Container>  
  );
}
const GrowInFull = keyframes`
  0% {
    height: 0vh;
  }

  100% {
    height: 100vh;
  }
`
const ShrinkOffFull = keyframes`
  0% {
    height: 100vh;
  }

  100% {
    height: 0vh;
  }
`
const ScaleIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`
const ScaleOff = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100000;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 0vh;
  align-items: center;
  justify-content: center;
  background-color: black;
  &.display {
    animation-name: ${GrowInFull};
    animation-fill-mode: forwards;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
  }
  &.displayNone {
    height: 100vh;
    animation-name: ${ShrinkOffFull};
    animation-fill-mode: forwards;
    animation-duration: .7s;
    animation-timing-function: ease-in-out;
    animation-delay: .3s;
  }
`
const Form = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  opacity: 0;
  &.display {
    animation-name: ${ScaleIn};
    animation-fill-mode: forwards;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-delay: .3s;
  }
  &.displayNone {
    opacity: 1;
    animation-name: ${ScaleOff};
    animation-fill-mode: forwards;
    animation-duration: .5s;
    animation-timing-function: ease-in-out;
  }

`
const Login = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 4px;
  width: 98%;
  max-width: 450px;
  height: auto;
  padding: 20px;
  background-color: black;
  box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
  @media (max-width: 450px) {
    max-width: 100%;
    width: 100%;
    box-shadow: none;
  }
`

const CloseIconeContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  margin: 0px;
  cursor: pointer;
  justify-content: center;
  z-index: 100000;
  opacity: 0;
  animation-name: ${ScaleIn};
  animation-fill-mode: forwards;
  animation-duration: .1s;
  animation-timing-function: ease-in-out;
  animation-delay: .3s;
`
const CloseIcone = styled(Close)`
  color: gray;
  width: 50px;
`

const AppLogo = styled.div`
  width: 100%;
`
const Logo = styled.div`
  color: grey;
  display: block;
  width: 100%;
  text-align: center;
  font-size: 35px;
  font-style: italic;
  padding: 20px 10px 0px 10px;
  margin: 0;
  box-sizing: border-box;
  cursor: context-menu;
  `
const RateItLogo = styled.img`
  width: 70%;
  height: auto;
  max-width: 400px;
`

const TitleWrapper = styled.div`
  position: relative;
  width:100%;
  box-sizing: border-box;
  padding: 70px 10% 0 10%;
  border-bottom: 1px solid grey;
  text-align: center;
`
const AltLoginTitle = styled.h3`
 color: grey;
 padding-bottom: 10px;
 margin: 0;
`

const RowContainer = styled.div`
   position: relative;
   width: 100%;
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: center;
   padding-top: 20px;
   padding-bottom: 20px;
   color: gray;
   @media (max-width: 450px) {
    flex-wrap: wrap;
  }
`
const IconContainerG = styled.div`
  width: 47%;
  height: 50px;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  margin: 10px;
  padding: 10px;
  color: black;
  background-color: whitesmoke;
  cursor: pointer; 
  :active {
    background-color: orange;
    color: white;
  }
  @media (max-width: 450px) {
    margin: 20px 10px;
    max-width: 100%;
    width: 100%;
    height: 60px;
    justify-content: space-between;
  }
`
const IconContainerF = styled.div`
  width: 47%;
  height: 50px;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #3b5998;
  color: white;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  margin: 10px;
  padding: 10px;
  cursor: pointer; 
  :active {
    background-color: orange;
    color: white;
  }
  @media (max-width: 450px) {
    max-width: 100%;
    width: 100%;
    height: 60px;
    justify-content: space-between;
  }
`

const GoogleIconImg = styled.img`
  position: relative;
  width: auto;
  height: 100%;
  border-radius: 4px;
`
const FacebookIconImg = styled.img`
  position: relative;
  width: auto;
  height: 100%;
  border-radius: 4px;
`
const ProviderName = styled.h2`
  padding: 10px;
  font-size: 25px
`