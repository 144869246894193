import React from 'react'
import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';
import footer_img from '../../../../Img/circular_halftone_texture.jpeg';
import { GrLinkedin } from "react-icons/gr";
import { RiFacebookBoxFill, RiInstagramFill, RiYoutubeLine } from "react-icons/ri";


export const Footer = () => {
  const getAtualYear = () => {
    let date = new Date();
    return date.getFullYear();
  }
  return (
    <FooterContainer>
      <FooterContainerRow id="other_documents">
        <FooterContainerColumn>
          <DocumentLinkR to="/pravne-dokumenty">Právne dokumenty</DocumentLinkR>
          <DocumentLinkR to="/rateit-pravidla">Rate it pravidlá</DocumentLinkR>
          <DocumentLinkR to="/gdpr">GDPR</DocumentLinkR>
        </FooterContainerColumn>
        <FooterContainerColumn>
          <DocumentLinkR to="/navrhy-na-zlepsenie">Hlasovanie za návrhy na zlepšenie</DocumentLinkR>
          <DocumentLinkR to="/novy-subjekt">Pridaj nový subjekt hodnotenia</DocumentLinkR>
          <DocumentLinkR to="/pravidla-bodovacieho-systemu">Pravidlá bodovacieho systému</DocumentLinkR>
        </FooterContainerColumn>
        <FooterContainerColumn>
          <DocumentLinkR to="/stan-sa-sponzorom">Staň sa sponzorom</DocumentLinkR>
          <DocumentLinkR to="/pre-firmy">Pre firmy </DocumentLinkR>
          <DocumentLinkR to="/kariera">Kariéra</DocumentLinkR>
        </FooterContainerColumn>
        <FooterContainerColumn>
          <OverlayContainer>
            <p>{`Copyright © ${getAtualYear()} All Rights Reserved By`}</p>
            <span>Some Fanatics group</span>
            <SocialContainer>
              <a href="https://www.facebook.com/Rateitsk-100657942414522"><FacebookIcone alt="Facebook stránka"/> </a>
              <a href="https://www.youtube.com/channel/UC4JyFnemqfw2JLrxYc-nG1Q"><YoutubeIcone alt="Youtube kanál"/></a>
              <a href="https://www.instagram.com/rateit.sk/"><InstagramIcone alt="Instagram konto"/></a>
              <a href="https://www.linkedin.com/in/rate-it-56799b223/"><LinkedinIcone alt="Linkedin stránka"/></a>
            </SocialContainer>
          </OverlayContainer>
        </FooterContainerColumn>
      </FooterContainerRow>
    </FooterContainer>
  )
}

// ---- STYLES ----
const FooterContainer = styled.div`
  width: 100%;
  height: 100%;
  border-top: 2px solid orange;
  background-image: url(${footer_img});
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
`
const FooterContainerRow = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
  color: whitesmoke;
  background-color: rgba(0, 0, 0, 0.8);
`

const FooterContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
`

const OverlayContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 30px 25px 20px 25px;
  background-color: rgba(0, 0, 0, .3);
  text-align: center;
  border-radius: 4px;
  p {
    padding-bottom: 7px;
  }
  span {
    font-family: 'Reenie Beanie', 'Bradley Hand', cursive;
    color: #00e1ff;
    font-size: 30px;
  }

`
const SocialContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: 15px 0 0 0;
  padding-top: 20px;
  border-top: 2px solid rgba(245, 245, 245, .4);
`
const DocumentLinkR = styled(LinkR)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  color: whitesmoke;
  text-decoration: none;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 5px 15px;
  letter-spacing: .5px;

  :hover {
    text-decoration: underline;
  }
`;
// SOCIAL ICONES
const FacebookIcone = styled(RiFacebookBoxFill)`
  width: 38px;
  height: 38px;
  color: rgba(245, 245, 245, .6);
`
const YoutubeIcone = styled(RiYoutubeLine)`
  width: 42px;
  height: 45px;
  color: rgba(245, 245, 245, .6);
`
const InstagramIcone = styled(RiInstagramFill)`
  width: 34px;
  height: 34px;
  color: rgba(245, 245, 245, .6);
`
const LinkedinIcone = styled(GrLinkedin)`
  width: 30px;
  height: 30px;
  color: rgba(245, 245, 245, .6);
`