import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { MainMenu } from './child_components/MainMenu'
import { MobileMenu } from './child_components/MobileMenu'
import { useComponent } from "../../../../Contexts/ComponentContext";
import { RateItLogo } from '../../../../Wigets/RateItLogo';

export const Header = () => {
  const [ displayStandardHeader, setDisplayStandardHeader ] = useState(true);
  let { heroOffset, setHeaderColor } = useComponent();

  const listenToScrollEvent = (e) => {
    if(window.scrollY > (heroOffset - 60)) { // Height of hero section - header height
      setDisplayStandardHeader(false);
      setHeaderColor("gray");
    } else {
      setDisplayStandardHeader(true);
      setHeaderColor("black");
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', listenToScrollEvent);
  
    return () =>
      window.removeEventListener('scroll', listenToScrollEvent);
  }, [heroOffset]);
  return (
    <DummyContainer>
      {displayStandardHeader ? 
        <StandardHeaderContainer>
          <RateItLogo/>
          <MainMenu />
          <MobileMenu/>
        </StandardHeaderContainer>
        :
        <NewHeaderContainer>
          <RateItLogo/>
          <MainMenu />
          <MobileMenu/>
        </NewHeaderContainer>
      }
    </DummyContainer>
  )
}

// ---- STYLES ----

const DummyContainer = styled.div`
  position: sticky;
  top: 0;
  margin: -10px 0 0 0;
  padding: 0;
  width: 100%;
  height: 61px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index:10000;
  @media screen and (max-width: 700px) {
    height: 61px;
  }
`
const StandardHeaderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  color: whitesmoke;
  background-color: rgba(0, 0, 0, 0.5);
`
const NewHeaderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  color: whitesmoke;
  background-color: rgb(80, 80, 80);
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 50px 0 rgb(0 0 0 / 10%);
`