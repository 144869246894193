/* TODOS:
 *    Nech button uložiť funguje a nech vyhodí loading
 *    Skús prísť nato ako logovat transaction time a ako zistiť koľko krát sa zmenil state
 */

import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Header } from "../Home_page/components/Header/Header";
import { useDatabase } from "../../Contexts/DatabaseContext";
import { FieldMessage } from "../../Wigets/FieldMessage";
import { useAuth } from "../../Contexts/AuthContext";

export const UserProfilePage = () => {
  // STATES
  const [regDate, setRegDate] = useState("none");
  const [nicknameValue, setNicknameValue] = useState("");
  const [inputDisabled, setInputDisabled] = useState("disabled");
  const [saveBtnDisabled, setSaveBtnDisabled] = useState("disabled");
  // REFS
  const nameRadio = useRef(null);
  const nicknameRadio = useRef(null);
  const nicknameInput = useRef(null);
  // CONTEXTS
  const { currentUser } = useAuth();
  const {
    userNicnameCheck,
    userProfile,
    userInitialsStr,
    updateUserProfile,
    message,
  } = useDatabase();
  // FUNCTIONS
  const slovakDate = (miliseconds_string) => {
    if (typeof miliseconds_string !== "undefined") {
      const date = new Date(miliseconds_string);
      const dd =
        String(date.getDay()).length < 2 ? "0" + date.getDay() : date.getDay();
      const mm =
        String(date.getMonth()).length < 2
          ? "0" + date.getMonth()
          : date.getMonth();
      const yyyy = date.getFullYear();
      setRegDate(`${dd}.${mm}.${yyyy}`);
    }
  };

  const isUndefined = (value) => {
    if (typeof value === "undefined") {
      return true;
    } else {
      return false;
    }
  };
  const handleRadioClick = () => {
    if (nicknameRadio.current.checked && inputDisabled === "disabled") {
      setInputDisabled(false);
      activateSaveBtn();
    } else if (nameRadio.current.checked && inputDisabled !== "disabled") {
      setNicknameValue("");
      setInputDisabled("disabled");
      activateSaveBtn();
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // post new values
    updateUserProfile(currentUser.uid, nicknameValue);
    // refresh page and load new informations
    //window.location.reload(false);
  };
  const handleNicnameCheck = (e) => {
    e.preventDefault();
    // check nickname availability
    userNicnameCheck(nicknameInput.current.value);
  };
  const activateSaveBtn = () => {
    // make Save button clicable
    setSaveBtnDisabled(false);
  };
  // LISTENERS
  useEffect(() => {
    nameRadio.current.checked = true;
    if (typeof userProfile.nic != "undefined" && userProfile.nic.length > 0) {
      nicknameRadio.current.checked = true;
      setNicknameValue(userProfile.nic);
      slovakDate(userProfile.firstLogin.milliseconds);
    }
  }, [userProfile]);

  useEffect(() => {
    if (nicknameRadio.current.checked) {
      setInputDisabled(false);
    } else {
      setInputDisabled("disabled");
    }
  });

  return (
    <MainContainer>
      <Header />
      <ContentContainer>
        <PageTitle>
          <h1>Profil</h1>
        </PageTitle>
        <Row>
          <LeftColumn>
            <LeftAlign>
              <Form action="" method="post" onSubmit={handleSubmit}>
                <h2>Používať:</h2>
                <Row>
                  <input
                    type="radio"
                    name="name_or_nickname_radio"
                    id="name_radio"
                    ref={nameRadio}
                    onClick={handleRadioClick}
                  />
                  <label htmlFor="name_radio">Meno</label>
                </Row>
                <Row>
                  <input
                    type="radio"
                    name="name_or_nickname_radio"
                    id="nickname_radio"
                    ref={nicknameRadio}
                    onClick={handleRadioClick}
                  />
                  <label htmlFor="nickname_radio">Pseudonym</label>
                  <NicknameInput
                    type="text"
                    name="nickNameInp"
                    id="nickNameInp"
                    value={nicknameValue}
                    ref={nicknameInput}
                    disabled={inputDisabled}
                    onKeyUp={activateSaveBtn}
                    onChange={(e) => setNicknameValue(e.target.value)}
                  />
                </Row>
                <Row>
                  <FieldMessage
                    disabledClass={inputDisabled}
                    message={message}
                  />
                </Row>
                <Row className="nowrap">
                  <Button
                    type="submit"
                    onClick={handleNicnameCheck}
                    disabled={inputDisabled}
                  >
                    Overiť
                  </Button>
                  <Button type="submit" disabled={saveBtnDisabled}>
                    Uložiť
                  </Button>
                </Row>
              </Form>
            </LeftAlign>
          </LeftColumn>
          <RightColumn>
            <UserBadgeContainer>
              <UserBadge>
                <UserInitials>{userInitialsStr}</UserInitials>
              </UserBadge>
              <UserNameTitle>
                {userProfile.nic !== ""
                  ? String(userProfile.nic)
                  : String(userProfile.name)}
              </UserNameTitle>
            </UserBadgeContainer>
          </RightColumn>
        </Row>
        <Row>
          <StatisticsTitle>Štatiskyky používania:</StatisticsTitle>
        </Row>
        <Row>
          <Table>
            <tbody>
              <tr>
                <td>Dátum registrácie:</td>
                <td>{regDate}</td>
              </tr>
              <tr>
                <td>Stav používateľa:</td>
                <td>aktívny</td>
              </tr>
              <tr>
                <td>Vytvorených subjektov:</td>
                <td>
                  {isUndefined(userProfile.subjects)
                    ? "..."
                    : String(userProfile.subjects.length)}
                </td>
              </tr>
              <tr>
                <td>Pridaných hodnotení:</td>
                <td>
                  {isUndefined(userProfile.ratings)
                    ? "..."
                    : String(userProfile.ratings.length)}
                </td>
              </tr>
              <tr>
                <td>Pridaných like:</td>
                <td>
                  {isUndefined(userProfile.nOfLikesTo)
                    ? "..."
                    : String(userProfile.nOfLikesTo)}
                </td>
              </tr>
              <tr>
                <td>Pridaných dislike:</td>
                <td>
                  {isUndefined(userProfile.nOfDislikesTo)
                    ? "..."
                    : String(userProfile.nOfDislikesTo)}
                </td>
              </tr>
              <tr>
                <td>Dostané like:</td>
                <td>
                  {isUndefined(userProfile.nOfLikesFrom)
                    ? "..."
                    : String(userProfile.nOfLikesFrom)}
                </td>
              </tr>
              <tr>
                <td>Dostané dislike:</td>
                <td>
                  {isUndefined(userProfile.nOfDislikesFrom)
                    ? "..."
                    : String(userProfile.nOfDislikesFrom)}
                </td>
              </tr>
            </tbody>
          </Table>
        </Row>
      </ContentContainer>
    </MainContainer>
  );
};
// ------ STYLES -------
const MainContainer = styled.div`
  background-color: whitesmoke;
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 700px;
  height: 100%;
  min-height: 100vh;
  padding-top: 60px;
  margin: 0 auto;
  @media (max-width: 700px) {
    width: 100%;
  }
`;

const Row = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px;
  @media (max-width: 700px) {
    flex-wrap: wrap;
    &.nowrap {
      flex-wrap: nowrap;
    }
  }
`;

const PageTitle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px;
  @media (max-width: 700px) {
    display: none;
  }
`;

const LeftColumn = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  @media (max-width: 700px) {
    order: 2;
    align-items: center;
  }
`;

const RightColumn = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  @media (max-width: 700px) {
    order: 1;
    align-items: center;
  }
`;

const LeftAlign = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-items: left;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;

  div {
    justify-content: left;
    align-items: left;
    padding-top: 10px;
  }
  label {
    padding-left: 7px;
    padding-right: 7px;
  }
  @media (max-width: 700px) {
    width: 100%s;
  }
`;
const NicknameInput = styled.input`
  width: 100%;
  border: 2px solid rgba(3, 168, 244, 0.773);
  border-radius: 4px;
  padding: 4px;
  :disabled {
    transition: border-color 250ms linear;
    border-color: rgba(128, 128, 128, 0.534);
  }
  @media (max-width: 700px) {
    margin-top: 10px;
  }
`;
const Button = styled.button`
  width: 47%;
  border: 2px solid orange;
  border-radius: 4px;
  padding: 5px;
  background-color: transparent;
  color: orange;
  margin: 7px 15px;
  font-weight: bold;
  cursor: pointer;
  :active {
    background-color: orange;
    color: whitesmoke;
  }
  :disabled {
    transition: border-color 250ms linear;
    border-color: rgba(128, 128, 128, 0.534);
    color: rgba(128, 128, 128, 0.534);
    cursor: initial;
  }
  @media (max-width: 700px) {
    width: 44%;
  }
`;

const Table = styled.table`
  width: 100%;
  td {
    border-bottom: dotted gray 1px;
    padding: 5px 0px;
  }
  td:nth-child(2) {
    text-align: right;
  }
  @media (max-width: 700px) {
    padding-right: 20px;
    padding-left: 15px;
  }
`;
const UserBadgeContainer = styled.div`
  padding: 25px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
`;

const UserBadge = styled.div`
  border: 2px solid orange;
  border-radius: 50%;
  background-color: grey;
  width: 150px;
  height: 150px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const UserInitials = styled.p`
  font-size: 4.5rem;
  font-weight: 300;
  color: orange;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

const UserNameTitle = styled.h1`
  padding: 20px;
  color: grey;
  text-align: center;
`;
const StatisticsTitle = styled.h2`
  width: 100%;
  text-align: left;
`;
