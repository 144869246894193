import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useAuth } from '../../../../../Contexts/AuthContext';
import { useComponent } from '../../../../../Contexts/ComponentContext';
import { Link as LinkR, useLocation } from "react-router-dom";
import { UserRectangle } from '@styled-icons/boxicons-solid/UserRectangle'
import { Gear } from '@styled-icons/octicons/Gear'
import { LogoutBoxR } from '@styled-icons/remix-fill/LogoutBoxR'
import { Close } from '@styled-icons/ionicons-outline/Close'
import { useDatabase } from '../../../../../Contexts/DatabaseContext';

export const UserMenu = () => {
  const [ isActiveOne, setIsActiveOne ] = useState("");
  const [ isActiveTwo, setIsActiveTwo ] = useState("");
  const { displayUserMenu, setDisplayUserMenu, setCompLoading, headerColor, userMenuClass, setUserMenuClass} = useComponent();
  const { userInitialsStr } = useDatabase();
  const { logout } = useAuth();
  const location = useLocation();

  const resetLinkStates = () => {
    setIsActiveOne("");
    setIsActiveTwo("");
  }

  useEffect(() => {
    if (location.pathname.indexOf("/profil") > -1) {
      setIsActiveOne("active");
      setIsActiveTwo("");
    } else if (location.pathname.indexOf("/nastavenia" ) > -1) {
      setIsActiveOne("");
      setIsActiveTwo("active");
    } else {
      resetLinkStates();
    }
  }, [location])

  const toggleMenuVisibility = () => {
    if (userMenuClass === "visible") {
      setUserMenuClass("");
      setDisplayUserMenu(false);
    } else {
      setUserMenuClass("visible");
      setDisplayUserMenu(true);
    }
  }
  const logoutClick = () => {
    setCompLoading(true);
    logout();
    setTimeout(() => {
      setCompLoading(false);
    }, 700);
  }
  return (
    <Container className = {userMenuClass}>
      <UserBadge onClick={toggleMenuVisibility}  className = {userMenuClass}>
        {displayUserMenu ?
          <CloseIcone/>
        :
          <UserInitials>
            {userInitialsStr}
          </UserInitials>
        }
      </UserBadge>

      <RightMenuContainer className = {headerColor + " " + userMenuClass}>
        <li className = {isActiveOne}>
        <LinkR to="/profil" onClick={toggleMenuVisibility}>
          <IconeContainer>
            <UserRectangle/>
          </IconeContainer>
          <LinkText>
            Profil
          </LinkText>
        </LinkR></li>
        <li className = {isActiveTwo}><LinkR to="/nastavenia" onClick={toggleMenuVisibility}>
          <IconeContainer style={{height: "24px", width: "24px"}}>
            <Gear/>
          </IconeContainer>
          <LinkText>Nastavenia</LinkText>
        </LinkR></li>
        <li><div onClick={() => logoutClick()}>
          <IconeContainer style={{height: "28px", width: "28px"}}>
            <LogoutBoxR/>
          </IconeContainer>
          <LinkText>Logout</LinkText>
        </div></li>
      </RightMenuContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: visible;
  padding: 0 15px 0px 15px;
  transition: all 0.35s ease-in-out;
  &.visible {
    padding: 0 76.5px;
    margin-right: -15px;
  }
  @media screen and (max-width: 800px) and (min-width: 700px) { 
    &.visible {
      padding: 0 15px 0px 15px;
      margin-right: 0;
    }
  }

  @media screen and (max-width: 700px) {
    display: none;
  }
`
const UserBadge = styled.div`
  border: 2px solid orange;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 37px;
  margin-right: 15px;
  overflow: hidden;
  transition: all 0.35s ease-in-out;
  :hover {
    background-color: rgb(255, 165, 0, 0.7);
  }
`
const UserInitials = styled.p`
  color: orange;
  :hover {
    color: black;
  }
`
const RightMenuContainer = styled.ul`
  position: absolute;
  top: 59px;
  right: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
  width: 0px;
  transition: width 0.35s ease-in-out;
  border-top: 2px solid orange;
  &.visible {
    width: 190px;
  }
  &.black {
    background-color: rgba(0, 0, 0, 1);
  }
  &.gray {
    background-color: gray;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 50px 0 rgb(0 0 0 / 10%);
  }

  li {
    list-style: none;
    margin: 0px;
    position: relative;
    outline: 0px;
    padding: 10px 20px;
    border-bottom: solid 2px orange;
    transition: all 0.35s ease-in-out;

    :hover,
    &.active {
      background-color: rgb(255, 165, 0, 0.7);
    }
  }

  li>a,
  li>div {
    color: white;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
  }
`
const IconeContainer = styled.div`
  height: 26px;
  width: 26px;
`
const LinkText = styled.span`
  padding-left: 30px;
`
const CloseIcone = styled(Close)`
  color: orange;
  transition: all 0.35s ease-in-out;
  :hover {
    color: whitesmoke;
  }
`
