import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDatabase } from "../../../../Contexts/DatabaseContext";
// ---- ICONS ----
import { IoSchool } from "react-icons/io5";
import { IoBusinessSharp } from "react-icons/io5";
import { FaBriefcaseMedical } from "react-icons/fa";
import { FaChartPie } from "react-icons/fa";
import { FaBed } from "react-icons/fa";
import { FaBoxOpen } from "react-icons/fa";
import { RiCustomerService2Fill } from "react-icons/ri";
import { RiTeamFill } from "react-icons/ri";
import { MdShoppingCart } from "react-icons/md";
import { SiNike } from "react-icons/si";
import { BsFillPlusSquareFill } from "react-icons/bs";

const getIconeComponent = (cagegory_name) => {
  let answer = "";
  let inlineIconeStyles = {
    height: "inherit",
    width: "inherit",
    color: "grey",
  };
  switch (cagegory_name) {
    case "Školstvo":
      answer = <IoSchool style={inlineIconeStyles} />;
      break;
    case "Značky":
      answer = <SiNike style={inlineIconeStyles} />;
      break;
    case "Ubytovanie":
      answer = <FaBed style={inlineIconeStyles} />;
      break;
    case "Produkty":
      answer = <FaBoxOpen style={inlineIconeStyles} />;
      break;
    case "Zdravotníctvo":
      answer = <FaBriefcaseMedical style={inlineIconeStyles} />;
      break;
    case "Obchody":
      answer = <MdShoppingCart style={inlineIconeStyles} />;
      break;
    case "Organizácie a právnické osoby":
      answer = <RiTeamFill style={inlineIconeStyles} />;
      break;
    case "Služby":
      answer = <RiCustomerService2Fill style={inlineIconeStyles} />;
      break;
    case "Politické strany a hnutia":
      answer = <FaChartPie style={inlineIconeStyles} />;
      break;
    case "Zamestnávatelia":
      answer = <IoBusinessSharp style={inlineIconeStyles} />;
      break;
    case "Nezaradené":
      answer = <BsFillPlusSquareFill style={inlineIconeStyles} />;
      break;

    default:
      answer = "";
      break;
  }
  return answer;
};

export const Categories = () => {
  const { getParentCategories } = useDatabase();
  const [categoriesArray, setCategoriesArray] = useState([]);
  useEffect(() => {
    getParentCategories().then((array) => setCategoriesArray(array));
  }, []);
  return (
    <Container>
      <Title>Hlavné kategórie</Title>
      <CategoryWrapper>
        {categoriesArray.map((category) => {
          return (
            <CategoryItem key={category.id}>
              <CategoryName>{category.name}</CategoryName>
              <IconeContainer>
                {getIconeComponent(category.name) || ""}
              </IconeContainer>
              <CategoryFooter>
                <CategoryFooterItem>
                  Subjektov: <br></br> {category.n_of_subjects}
                </CategoryFooterItem>
                <CategoryFooterItem>
                  Hodnotení: <br></br> {category.n_of_ratings}
                </CategoryFooterItem>
              </CategoryFooter>
            </CategoryItem>
          );
        })}
      </CategoryWrapper>
    </Container>
  );
};

// ---- STYLES ----
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  max-width: 100%;
`;
const Title = styled.h2`
  width: 100%;
  text-transform: uppercase;
  color: orange;
  font-size: 2em;
  margin-top: 10px;
`;
const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
`;
const CategoryItem = styled.div`
  width: 280px;
  min-width: 280px;
  height: 280px;
  border-bottom: 2px solid orange;
  border-radius: 4%;
  margin: 20px 10px;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 3px -2px,
    rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: all ease-in-out 0.4s;
  cursor: pointer;
  :hover {
    transform: scale(1.1);
  }
  :active {
    background-color: orange;
    color: white;
    transition: all ease-in-out 0.1s;
  }
`;
const CategoryName = styled.h3`
  margin-top: 10%;
  font-size: 30px;
  text-align: center;
`;
const IconeContainer = styled.div`
  height: 100px;
  width: 100px;
  color: orange;
`;
const CategoryFooter = styled.div`
  margin-bottom: 5%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;
const CategoryFooterItem = styled.div`
  text-align: center;
  font-style: italic;
`;
