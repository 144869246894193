import React, { useState, useEffect, useContext } from 'react';

const ComponentContext = React.createContext();

export function useComponent() {
  return useContext(ComponentContext);
}

export function ComponentProvider({ children }) {
  const [ heroOffset, setHeroOffset ] = useState(0);
  const [ compLoading, setCompLoading ] = useState(false);
  const [ userMenuClass, setUserMenuClass ] = useState("");
  const [ headerColor, setHeaderColor ] = useState("black");
  const [ displayLogin, setDisplayLogin ] = useState(false);
  const [ displayUserMenu, setDisplayUserMenu ] = useState(false);
  const [ displayMobileMenu, setDisplayMobileMenu] = useState(false);
 
  useEffect(() => {
    if (displayLogin || displayMobileMenu || displayUserMenu) {
      document.querySelector("body").style.overflow = "hidden"; 
    } else {
      document.querySelector("body").style.overflow = "auto"; 
    }
  }, [displayLogin, displayMobileMenu, displayUserMenu])

  const value = { heroOffset, setHeroOffset, userMenuClass, setUserMenuClass, compLoading, setCompLoading, displayLogin, setDisplayLogin, headerColor, setHeaderColor, displayUserMenu, setDisplayUserMenu, setDisplayMobileMenu};
  return (
    <ComponentContext.Provider value={value}>
      {children}
    </ComponentContext.Provider>
  )
}
