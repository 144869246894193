import React from 'react';
import { HomePage } from './Pages/Home_page/HomePage';
import { Preloader } from './Wigets/Preloader';
import { LoginForm } from './Wigets/LoginForm'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { useComponent } from "./Contexts/ComponentContext";
//import "firebase/app-check";
import { useAuth } from './Contexts/AuthContext';
import { InDevelopmentPage } from './Pages/In_development_page/InDevelopmentPage';
import { UserProfilePage } from './Pages/User_profile_page/UserProfilePage';



function App() {
  const { isLogedIn, loading } = useAuth();
  const { displayLogin, compLoading } = useComponent();
  return (
    <Router>
      <div className="App">
        {loading || compLoading ? <Preloader/> : null}
        {!isLogedIn && displayLogin ? <LoginForm/> : null}
        <Switch>
          <Route exact path="/"><HomePage display={!displayLogin}/></Route>
          <Route path="/pravne-dokumenty"><InDevelopmentPage /></Route>
          <Route path="/rateit-pravidla"><InDevelopmentPage /></Route>
          <Route path="/gdpr"><InDevelopmentPage /></Route>
          <Route path="/navrhy-na-zlepsenie"><InDevelopmentPage /></Route>
          <Route path="/novy-subjekt"><InDevelopmentPage /></Route>
          <Route path="/pravidla-bodovacieho-systemu"><InDevelopmentPage /></Route>
          <Route path="/stan-sa-sponzorom"><InDevelopmentPage /></Route>
          <Route path="/pre-firmy"><InDevelopmentPage /></Route>
          <Route path="/kariera"><InDevelopmentPage /></Route>
          <Route path="/profil"><UserProfilePage /></Route>
          <Route path="/nastavenia"><InDevelopmentPage /></Route>
          <Route path="/pridaj-hodnotenie"><InDevelopmentPage /></Route>
          <Route path="/vyhladaj-hodnotenie"><InDevelopmentPage /></Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
