import React from "react";
import styled from 'styled-components';
import { ContactForm } from './ContactForm';

export const ContactUs = () => {
  
  return (
    <ContactUsContainer id="contact_us">
      <LeftContainer>
        <Title>Či už más nápad na zlepšenie, chceš nahlásiť chybu alebo sa chceš na niečo spýtať, neváhaj nám napísať. </Title>
        <Article>Aj keď máme množstvo nádadov ako vylepšiť tento protál z hodnoteniami a aké nové funkcie by sa ešte zišli <span><strong>tvôj názor</strong></span> je prenás rovnako dôležitý. Takže ak máš nejaké nápady ako naše hodnotenia vylepšiť alebo aké nové funkcie máme pridať, určite nám napíš. Aj keď pre nás nebude vždy možné doprogramovať každý nápad, budeme ti za každý veľmi vďačný. Poprídade ak máš nejakú stažnosť alebo iný druh feedbacku ... však vieš čo potom robiť. :)</Article> 
      </LeftContainer>
      <RightContainer>
        <ContactForm/>
      </RightContainer>
    </ContactUsContainer>
  );
};

// ---- STYLES ----
const ContactUsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 70px 5px;
  background-color: gray;
  color: white;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`
const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: center;
  max-width: 53%;
  padding: 20px 30px;
  border: 2px solid orange;
  border-radius: 4px;
  @media screen and (max-width: 1024px) {
    width: 90%;
    max-width: 90%;
  }
`
const Title = styled.h1`
 font-size: 30px;
 text-align: justify;
 border-bottom: 2px solid orange;
 padding-bottom: 30px;
 margin: 20px 0 25px 0;
`
const Article = styled.article`
  text-align: justify;
  font-size: 17px;
`
const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  text-align: left;
  max-width: 45%;
  @media screen and (max-width: 1024px) {
    width: 100%;
    max-width: 100vw;
    padding-top: 90px;
  }
`