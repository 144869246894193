import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";

export const AboutUs = () => {
  const [showMore, setShowMore] = useState(false);
  const [expanded, setExpanded] = useState("close");

  const tggleShowMore = () => {
    setExpanded((currentState) =>
      currentState === "expanded" ? "closed" : "expanded"
    );
    setShowMore((currentState) => !currentState);
  };
  return (
    <AboutUsContainer id="about_us">
      <DinamicHightContainer className={expanded}>
        <ContentContainer>
          <LeftTopColumn>
            <MiddleBorderContainer>
              <TitleContainer>
                <h1>O NÁS</h1>
              </TitleContainer>
              <div>
                <MiddleBorder />
              </div>
              <p>
                Ahojte, moje meno je Igor a v týchto pár riadkoch sa vás budem
                snažiť neunudiť na smrť. Nápad na tento portál som dostal pred 4
                rokmi a to priamo na svojej svadobnej ceste. Uisťujem vás, že aj
                napriek tomu bola svadobná cesta úspešná. Myšlienka bola
                jednoduchá, ale ani napriek tomu to nikto predo mnou neurobil.
                My všetci na internete hľadáme odpovede na rôzne otázky. Ktorého
                doktora si mám vybrať? Ktorá zo súkromných škôl stojí za tie
                peniaze? Kde mi urobia najkvalitnejšiu manikúru a nie moc draho?
                Kde v meste nájdem najchutnejší hamburger? Tieto a tisícky
                ďalších otázok nás dennodenne trápia a nútia hľadať recenzie,
                hodnotenia, články a obrázky a to na rôznych internetových
                stránkach. Prečo všetky tieto veci nevieme nájsť na jednom
                mieste? Tak to tu máte, základnou myšlienkou tohto portálu je
                napchať všetko, čo ľudom pomôže sa kvalitne rozhodnúť na jedno
                miesto. Človek si povie čo na tom môže byť ťažké, nie? No
                ukázalo sa, že vlastne všetko. K tomu momentu som ešte nevedel
                skoro vôbec programovať a neostávalo mi nič iné, len sa to
                naučiť. Štyri roky od toho momentu uplynuli a sme tu. Cieľom
                tohto portálu je pomáhať ľudom.
              </p>
            </MiddleBorderContainer>
          </LeftTopColumn>
          <EmptyDiv></EmptyDiv>
          <EmptyDiv></EmptyDiv>
          <RightMiddleColumn>
            <MiddleBorderContainer>
              <TitleContainer>
                <h2>Spoznajte</h2>
              </TitleContainer>
              <div>
                <MiddleBorder />
              </div>

              <p>
                Okúsili ste už pocit keď sa doktor smeje vašim ťažkostiam? Alebo
                naopak doktora, ktorý vás ignoruje? Zažili ste predavačku s
                takým dobrým prístupom, že vás to tak pozitívne prekvapilo, že
                ste si chceli niečo kúpiť? Zažili ste šikanu zo strany polície?
                Alebo ste si našli stolára, ktorý bol rýchly, kvalitný a za
                perfektnú cenu? Všetky tieto zážitky môžu ostatným ľudom pomôcť
                sa správne rozhodnúť. A všetky takého hodnotenia patria práve
                sem na portál rateit.sk. Nie je skúsenosť príliš malá alebo
                príliš veľká, či už ide o kvalitu šnúrok do topánok alebo
                volebný program politických strán, všetko patrí sem a všetko tu
                aj nájdete. Len na rateit.sk môžete ohodnotiť naozaj hocičo.
                Samozrejme mimo eticky neprípustných tém :).
              </p>
            </MiddleBorderContainer>
          </RightMiddleColumn>
          <BottomLeftColumn>
            <MiddleBorderContainer>
              <TitleContainer>
                <h2>Spolahnite sa</h2>
              </TitleContainer>
              <div>
                <MiddleBorder />
              </div>
              <p>
                Už sú to viac ako štyri roky, čo sa učím programovať aby som
                dokázal vytvoriť tento portál. Mojim cieľom je, aby tento portál
                slúžil ľudom. Môžem vám s kľudným srdcom sľúbiť, že vždy budem
                klásť potreby užívateľov tohto portálu pred akékoľvek požiadavky
                spoločností, ktoré budú mať záujem o spoluprácu. Takisto som
                odhodlaný vám aj pozorne načúvať a vynasnažím sa implementovať
                každý váš návrh, ktorý bude v prospech väčšiny a na ktorý budem
                mať čas a peniaze. Takže neváhajte využiť sekciu s kontaktným
                formulárom, ak objavíte nejaký problém alebo budete mať
                zaujímavý nápad. Mám kopec nápadov ako to tu ešte zlepšiť, takže
                sa máte načo tešiť. Ak ste sa dočítali až sem, tak máte odo mňa
                veľký like. Rád by som vám odporučil si prečítať aj pravidlá
                zverejňovania hodnotení a hodnotiaci systém užívateľov, ktoré
                nájdete pod nasledovným link-om. Pravidlá zverejňovania
                hodnotení: • hovoria o tom, aké hodnotenia nebudú zverejnené,
                budú stiahnuté a za aký obsah môže byť užívateľ zablokovaný
                alebo úplne vymazaný. Hodnotiaci systém užívateľov: • tu sa
                dočítate na základe akých akcií získava užívateľ body a aký
                počet; • taktiež je tu opísané ,aký titul získate po dosiahnutí
                určitého počtu bodov; • po čase tu bude zverejňovaný aj počet
                bodov potrebný na výhru kúskov z nášho rateit.sk merch-u.
              </p>
            </MiddleBorderContainer>
          </BottomLeftColumn>
        </ContentContainer>
      </DinamicHightContainer>
      {showMore ? (
        <SectionFooterExpand onClick={() => tggleShowMore()}>
          <h4>Zrolovať</h4>
          <DestroySectionIcone />
        </SectionFooterExpand>
      ) : (
        <SectionFooterClose onClick={() => tggleShowMore()}>
          <h4>Zisti viac</h4>
          <ExtendSectionIcone />
        </SectionFooterClose>
      )}
    </AboutUsContainer>
  );
};

// ---- STYLES ----

const AboutUsContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 60px;
  background-color: whitesmoke;
`;
const GrowInFull = keyframes`
  0% {
    height: 0%;
    opacity: 0;
  }

  100% {
    height: 100%;
    opacity: 1;
  }
`;
const Expand = keyframes`
  0% {
    height: 25vh;
  }
  99% {
    height: 100vh;
  }
  100% {
    height: 100%;
  }
`;
const Close = keyframes`
  0% {
    height: 100%;
  }
  10% {
    height: 100vh;
  }
  100% {
    height: 25vh;
  }
`;

const DinamicHightContainer = styled.div`
  width: 100%;
  height: 25vh;
  overflow: hidden;
  &.expanded {
    animation-name: ${Expand};
    animation-fill-mode: forwards;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
  }
  &.closed {
    animation-name: ${Close};
    animation-fill-mode: forwards;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
  }
`;
const EmptyDiv = styled.div``;
const ContentContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  padding: 5px 5px 60px 5px;
  @media screen and (max-width: 800px) {
    ${EmptyDiv} {
      display: none;
    }
    grid-template-columns: 1fr;
    gap: 50px;
  }
`;

const TitleContainer = styled.div`
  position: relative;
  min-width: 39px;
`;
const MiddleBorder = styled.div`
  position: relative;
  width: 1px;
  height: 0%;
  opacity: 0;
  border-left: 4px solid orange;
`;

const MiddleBorderContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 5px;
  text-align: justify;
  h1,
  h2 {
    padding-right: 10px;
    position: absolute;
    top: 0;
    right: 0;
    color: orange;
    font-size: 2em;
    transform-origin: bottom right;
    transform: rotate(270deg) translate(45px, -5px);
    white-space: nowrap;
  }
  & div ${MiddleBorder} {
    animation-name: ${GrowInFull};
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
    animation-duration: 1s;
  }
  p {
    padding-left: 15px;
    padding-right: 15px;
  }
  @media screen and (max-width: 800px) {
    justify-content: center;
  }
`;

const LeftTopColumn = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: left;
  @media screen and (max-width: 800px) {
    justify-content: center;
  }
`;
const RightMiddleColumn = styled.div``;
const BottomLeftColumn = styled.div``;
const SectionFooterClose = styled.div`
  justify-self: flex-end;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: whitesmoke;
  background-color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
`;
const SectionFooterExpand = styled.div`
  justify-self: flex-end;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: whitesmoke;
  background-color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
`;
const ExtendSectionIcone = styled(IoIosArrowDown)`
  width: 20px;
  height: 20px;
`;
const DestroySectionIcone = styled(IoIosArrowUp)`
  width: 20px;
  height: 20px;
`;
