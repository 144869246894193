import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { Header } from "./components/Header/Header";
import { Footer } from "./components/Footer/Footer";
import { Hero } from "./components/Hero/Hero";
import { AboutUs } from "./components/AboutUs/AboutUs";
import { ContactUs } from "./components/ContactUs/ContactUs";
import { Categories } from "./components/Categories/Categories";

export const HomePage = ({ display }) => {
  const [displayToggleClass, setDisplayToggleClass] = useState("");
  useEffect(() => {
    if (display === true) {
      setDisplayToggleClass("");
    } else {
      setTimeout(() => {
        setDisplayToggleClass("display_none");
      }, 1500);
    }
  }, [display]);

  return (
    <HomePageContainer className={displayToggleClass}>
      <Helmet>
        <title>Tu ohodnotíš čokolvek!</title>
        <meta
          name="description"
          content="Rateit.sk je miesto kde môžete ohodnotiť hocičo a vaše hodnotenie zdieľať s celým slovenskom."
        />
      </Helmet>
      <Header />
      <Hero />
      <Categories />
      <AboutUs />
      <ContactUs />
      <Footer />
    </HomePageContainer>
  );
};

const HomePageContainer = styled.div`
  display: block;
  &.display_none {
    display: none;
  }
`;
